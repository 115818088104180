import styled, { css, keyframes } from 'styled-components';

const CountdownBar = ({ duration, isCountdownStart }) => {
  return (
    <CountdownBarWrapper>
      <Bar $isAnimationStart={isCountdownStart} $duration={duration} />
      <CountdownBackground />
    </CountdownBarWrapper>
  );
};

export default CountdownBar;

const CountdownBarWrapper = styled.div`
  position: relative;
  height: 3px;
  margin: 20px 20px 0 20px;
  border-radius: 5px;
`;

const CountdownAnimation = keyframes`
  0%   {width: 0%}
  100% {width: 100%}
`;

const Bar = styled.div`
  background-color: white;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  height: 100%;
  border-radius: 5px;
  position: absolute;
  z-index: 99;
  width: 0%;
  ${props =>
    props.$isAnimationStart &&
    css`
      animation: ${CountdownAnimation} ${props => props.$duration}s linear;
    `}
`;

const CountdownBackground = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  width: 100%;
  height: 3px;
  position: absolute;
  border-radius: 5px;
`;
