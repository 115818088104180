import { styled } from 'styled-components';
import i18n from 'i18n';

const Header = () => {
  return (
    <NavBar>
      <NavBarWrapper>
        <Brand href="/" aria-current="page">
          <img
            src="https://uploads-ssl.webflow.com/649bddd6c1906e34c52eafce/649bea2978d90b1aba8a6ec4_Headerlogo.svg"
            loading="lazy"
            alt=""
          />
        </Brand>
        <Navigation role="navigation">
          <NavLinksWrapper>
            <NavButton href="https://yoso.page.link/web-ios" target="_blank">
              {i18n.t('button_download')}
            </NavButton>
          </NavLinksWrapper>
        </Navigation>
      </NavBarWrapper>
    </NavBar>
  );
};

const NavBar = styled.div`
  margin-left: 18px;
  margin-right: 18px;
  padding-top: 15px;
  width: 90%;
`;

const NavBarWrapper = styled.div`
  z-index: 100;
  max-width: none;
  background-color: #fff;
  border-radius: 73px;
  margin-top: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  margin: auto;
`;

const Brand = styled.a`
  width: 80px;
  height: 35px;
  padding-left: 0;

  @media (max-width: 320px) {
    margin: auto;
  }
`;

const Navigation = styled.nav`
  max-width: 76%;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  display: flex;

  @media (max-width: 320px) {
    display: none;
  }
`;

const NavLinksWrapper = styled.div`
  flex: none;
`;

const NavButton = styled.a`
  color: #fff;
  background-color: #7b51ef;
  border-radius: 80px;
  padding: 14px 26px;
  font-size: 16px;
  line-height: 28px;
  transition: background-color 0.2s;
  text-decoration: none;
`;

export default Header;
