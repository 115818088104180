import Axios from 'axios';
import * as R from 'ramda';

const isProd = process.env.REACT_APP_ENV === 'production';
const API_ENV = isProd ? 'api' : 'api-stage';
const baseURL = `https://${API_ENV}.yosolink.app/`;
const axios = Axios.create({
  baseURL,
});

const getImageIDfromURL = () => {
  const fullURL = window.location.href;
  const imageID = fullURL.split('/').pop();

  return imageID;
};

// axios.interceptors.request.use(function (config) {
//   config.url = R.replace('/api/v1/', 'http://127.0.0.1:5001/yoso-dev/us-central1/', config.url);
//
//   return config;
// });

export const getImageMeta = async ({ key, id }) => {
  const isPreviewMode = !!key;
  try {
    const res = await axios.get('/api/v1/getPhotoMeta', {
      params: { id },
      headers: {
        'X-ONE-TIME-PREVIEW-TOKEN': key,
      },
    });
    const imageMeta = res?.data;
    const isImageExpired =
      imageMeta?.isViewed || !imageMeta?.imgSignedUrl || (isPreviewMode && imageMeta?.isPreviewed);
    imageMeta.isExpired = isImageExpired;

    if (isImageExpired) return { isExpired: true, imageID: id };
    return { ...imageMeta, imageID: id };
  } catch (e) {
    console.error('getPhotoMeta error', e);
    return { isExpired: true };
  }
};

export const updateImageIsViewed = async ({ key, id }) => {
  try {
    const timezone = Intl.DateTimeFormat().resolvedOptions()?.timeZone;
    await axios.post(
      '/api/v1/photoViewed',
      {
        id,
        timezone,
      },
      {
        headers: {
          'X-ONE-TIME-PREVIEW-TOKEN': key,
        },
      },
    );
  } catch (e) {
    console.error('updatePhotoViewed error', e);
  }
};
