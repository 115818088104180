import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import i18n, { language as browserLanguage } from 'i18n';

const Landing = () => {
  const [htmlContent, setHtmlContent] = useState('');
  const [searchParams] = useSearchParams();
  const utmSource = searchParams.get('utm_source');

  useEffect(() => {
    const fetchHtmlFile = async () => {
      const response = await fetch(`./static/landing/${browserLanguage}.txt`);
      const html = await response.text();

      //check whether if language file exist
      if (html.indexOf('©') !== -1) {
        setHtmlContent(html);
      } else {
        const defaultResponse = await fetch(`./static/landing/en.txt`);
        const html = await defaultResponse.text();
        setHtmlContent(html);
      }

      if (utmSource) {
        const regex =
          /https:\/\/(apps\.apple\.com\/app\/id6450738204|play\.google\.com\/store\/apps\/details\?id=com\.yoso\.www)/g;
        const newHtml = html.replace(regex, `https://yoso.page.link/${utmSource}`);

        setHtmlContent(newHtml);
      }
    };

    fetchHtmlFile();
  }, []);

  return (
    <>
      <Helmet>
        <title>{i18n.t('meta_title')}</title>
        <meta content={i18n.t('meta_description')} name="description" />
        <meta content={i18n.t('meta_title')} property="og:title" />
        <meta content={i18n.t('meta_description')} property="og:description" />
        <meta content={i18n.t('meta_title')} property="twitter:title" />
        <meta content={i18n.t('meta_description')} property="twitter:description" />
      </Helmet>
      <div
        style={{ background: '#ffffff' }}
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      ></div>
    </>
  );
};

export default Landing;
