import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import languageDetector from 'i18next-browser-languagedetector';

import en from './en.json';
import ko from './ko.json';
import ja from './ja.json';
import zh_TW from './zh_TW.json';
import zh_CN from './zh_CN.json';

const resources = {
  en: { translation: en },
  ko: { translation: ko },
  ja: { translation: ja },
  'zh-TW': { translation: zh_TW },
  'zh-CN': { translation: zh_CN },
};

i18n
  .use(initReactI18next)
  .use(languageDetector)
  .init({
    resources,
    fallbackLng: {
      zh: ['zh-TW', 'en'],
      'zh-CN': ['zh-TW', 'en'],
      'zh-TW': ['zh-CN', 'en'],
      default: ['en'],
    },
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['querystring', 'navigator', 'cookie', 'htmlTag'],
    },
  });

const formattedLanguage = (lng) => {
  const traditionalChineseCodes = ['zh', 'zh-tw', 'zh-hk', 'zh-mo', 'zh-hant'];
  const simplifiedChineseCodes = ['zh-cn', 'zh-sg', 'zh-hans'];
  const otherLanguage = lng.split('-')[0];

  if (traditionalChineseCodes.includes(lng.toLowerCase())) {
    return 'zh-tw';
  } else if (simplifiedChineseCodes.includes(lng.toLowerCase())) {
    return 'zh-cn';
  } else {
    return otherLanguage;
  }
};

export const language = formattedLanguage(i18n?.language);
export default i18n;
