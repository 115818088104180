import { ThemeProvider } from 'styled-components';
import { Routes, Route } from 'react-router-dom';
import ReactJson from 'react-json-view';

import theme from './theme';
import Preview from './page/Preview';
import Layout from './page/Layout';
import Landing from './page/Landing';
import Blog from './page/Blog';

function App() {
  return (
    <ThemeProvider theme={theme.default}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route
            path="/_env"
            element={
              <ReactJson
                src={{
                  version: process.env.REACT_APP_VERSION,
                  env: process.env.REACT_APP_ENV,
                }}
                theme="monokai"
              />
            }
          />
          <Route path="/" element={<Landing />} />
          <Route path="/:id" element={<Preview />} />
          <Route path="/line" element={<Blog type="line" />} />
          <Route path="/tinder" element={<Blog type="tinder" />} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;
