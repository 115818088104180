import { useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { path } from 'ramda';
import { isFunction, noop } from 'ramda-adjunct';

import i18n from 'i18n';

const FingerPoint = ({ style, className, isShowingTips, onEnter, onTrigger, onLeave }) => {
  const divRef = useRef(null);
  const isEntered = useRef(false);
  const isMobile = window.mobileAndTabletCheck();
  const enterOrTrigger = useCallback(() => {
    if (!isEntered.current) {
      if (isFunction(onEnter)) {
        onEnter();
      }
      isEntered.current = true;
    } else {
      if (isFunction(onTrigger)) {
        onTrigger();
      }
    }
  }, [onEnter, onTrigger]);

  const handleTouchStart = useCallback(
    event => {
      if (event.touches.length === 3) {
        enterOrTrigger();
      } else {
        if (isEntered.current && isFunction(onLeave)) {
          onLeave();
        }
      }
    },
    [enterOrTrigger, onLeave],
  );

  const handleTouchEnd = useCallback(() => {
    if (isEntered.current) {
      onLeave();
    }
  }, [onLeave]);

  const handleKeyDown = useCallback(
    e => {
      if (!isShowingTips) {
        return;
      }
      if (e.key === ' ') {
        enterOrTrigger();
      }
    },
    [isShowingTips, enterOrTrigger],
  );
  const handleKeyUp = useCallback(
    e => {
      if (e.key === ' ' && isFunction(onLeave)) {
        onLeave();
      }
    },
    [onLeave],
  );
  useEffect(() => {
    divRef.current?.focus();
  }, []);
  return (
    <Wrapper
      ref={divRef}
      style={style}
      className={className}
      onKeyDown={isMobile ? noop : handleKeyDown}
      onKeyUp={isMobile ? noop : handleKeyUp}
      onTouchStart={isMobile ? handleTouchStart : noop}
      onTouchEnd={isMobile ? handleTouchEnd : noop}
      tabIndex="0"
      autoFocus
      $isMobile={isMobile}
    >
      {isMobile ? (
        <>
          <Tips $isShowing={isShowingTips}>{i18n.t('mask_tips_mobile')}</Tips>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              opacity: isShowingTips ? 1 : 0,
            }}
          >
            <defs>
              <mask id="mask" x="0" y="0" width="100%" height="100%">
                <rect x="0" y="0" width="100%" height="100%" fill="white" />
                <circle cx="15%" cy="60%" r="45" fill="black" />
                <circle cx="20%" cy="78%" r="45" fill="black" />
                <circle cx="85%" cy="75%" r="45" fill="black" />
              </mask>
            </defs>
            <rect width="100%" height="100%" fill="rgba(0, 0, 0, 0.5)" mask="url(#mask)" />
          </svg>
        </>
      ) : (
        <Tips $isShowing={isShowingTips}>{i18n.t('mask_tips_desktop')}</Tips>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  &:focus {
    outline: none;
  }
`;

export const Tips = styled.div`
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                        supported by Chrome, Edge, Opera and Firefox */
  color: ${path(['theme', 'white'])};
  font: ${path(['theme', 'fonts', 'h3'])};
  z-index: 1;
  align-self: center;
  padding-top: 20px;
  visibility: ${props => (props.$isShowing ? 'visible' : 'hidden')};
`;

export default FingerPoint;
