import { useCallback, useEffect, useState } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import i18n from 'i18n';
import { getImageMeta, updateImageIsViewed } from 'core/api/api';
import { logEvent, startTimeEvent } from 'core/bi/biUtils';
import Conditional from 'components/Conditional';
import ExpiredContent from 'components/ExpiredContent';
import Mask from 'components/Mask';
import CloseButton from 'components/CloseButton';
import CountdownBar from 'components/CountdownBar';
import loadingImage from 'assets/eyeballs.png';

const Preview = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const [imageData, setImageData] = useState();
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isImageBlur, setIsImageBlur] = useState(true);
  const [isExpired, setIsExpired] = useState(false);
  const [isCountdownStart, setIsCountdownStart] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [second, setSecond] = useState();

  const duration = imageData?.duration || 5;
  const imageUrl = imageData?.imgSignedUrl;
  const isImageValid = !!imageUrl && !isExpired;

  useEffect(() => {
    setIsLoading(!isExpired && !isImageLoaded);
  }, [isExpired, isImageLoaded]);

  const updateImageMeta = async () => {
    await updateImageIsViewed({ key: searchParams.get('key'), id });
  };

  const setImageHasExpired = () => {
    setIsExpired(true);
    setIsCountdownStart(false);
  };

  useEffect(() => {
    const getImageData = async () => {
      const data = await getImageMeta({ key: searchParams.get('key'), id });
      if (data?.isExpired) {
        setImageHasExpired();
        logEvent(`one_time_link_clicked`, {
          isExpired: true,
          imageID: data?.imageID,
          isSensitive: data?.isSensitive,
        });
        return;
      }
      setImageData(data);
      setSecond(data?.duration);
      logEvent(`one_time_link_clicked`, {
        isExpired: false,
        imageID: data?.imageID,
        isSensitive: data?.isSensitive,
      });
    };

    getImageData();
  }, [searchParams, id]);

  const showImage = useCallback(() => {
    startTimeEvent(`preview_end_on_close`);
    startTimeEvent(`preview_end_on_time_up`);
    setIsCountdownStart(true);
    setIsImageBlur(false);
    setTimeout(() => {
      logEvent(`preview_end_on_time_up`);
      setImageHasExpired();
    }, duration * 1000);
  }, [duration]);

  useEffect(() => {
    if (isCountdownStart) {
      const timer = setInterval(() => {
        setSecond(prev => {
          if (prev === 0) {
            clearInterval(timer);
            return 0;
          } else {
            return prev - 1;
          }
        });
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [isCountdownStart]);

  //disable right click
  useEffect(() => {
    const handleContextMenu = e => {
      e.preventDefault();
    };

    document.addEventListener('contextmenu', handleContextMenu);
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  useEffect(() => {
    return () => {
      const metaTag = document.querySelector(`meta[name="description"]`);
      if (metaTag) {
        metaTag.remove();
      }
    };
  }, []);

  return (
    <Wrapper>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        {/* <meta content="https://i.imgur.com/j8mVnCb.gif" property="og:image" /> */}
        <title>{i18n.t('meta_title')}</title>
        <meta content={i18n.t('meta_description')} name="description" />
        <meta content={i18n.t('meta_title')} property="og:title" />
        <meta content={i18n.t('meta_description')} property="og:description" />
        <meta content={i18n.t('meta_title')} property="twitter:title" />
        <meta content={i18n.t('meta_description')} property="twitter:description" />
      </Helmet>
      <Conditional condition={isLoading}>
        <LoadingWrapper>
          <LoadingImage src={loadingImage} />
          <LoadingText>{i18n.t('loading')}</LoadingText>
        </LoadingWrapper>
      </Conditional>

      <Conditional condition={!isImageValid}>
        <ExpiredContent />
      </Conditional>

      <Conditional condition={isImageValid}>
        <PreviewWrapper>
          <ImagePreview
            src={imageUrl}
            $isImageBlur={isImageBlur}
            alt="image-preview"
            onLoad={() => {
              setIsImageLoaded(true);
              updateImageMeta();
            }}
            onError={() => setImageHasExpired()}
          />
        </PreviewWrapper>

        <CountdownWrapper>
          <CountdownBar isCountdownStart={isCountdownStart} duration={duration} />

          <CloseButtonWrapper>
            <Desc>
              {second}
              {i18n.t('second')}
            </Desc>
            <CloseButton
              onClick={() => {
                logEvent(`preview_end_on_close`);
                setImageHasExpired();
              }}
            />
          </CloseButtonWrapper>
        </CountdownWrapper>

        {isImageLoaded && (
          <Mask
            isSensitive={imageData?.isSensitive}
            isShowingTips={isImageBlur}
            onClickCloseButton={() => setImageHasExpired()}
            onTrigger={() => {
              setIsImageBlur(false);
            }}
            onEnter={() => {
              if (imageData?.isSensitive) {
                logEvent(`click_sensitive_show`);
              }
              showImage();
              logEvent('enter_view_photo');
            }}
            onLeave={() => {
              setIsImageBlur(true);
            }}
          />
        )}
      </Conditional>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: black;
  overflow: hidden;
  position: relative;
`;

const PreviewWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const ImagePreview = styled.img`
  height: 100%;
  object-fit: cover;
  filter: blur(${props => (props.$isImageBlur ? 60 : 0)}px);
  pointer-events: none;
  align-self: center;
  max-width: 100%;
`;

const Desc = styled.div`
  text-align: center;
  color: ${props => props.theme.white};
  font: ${props => props.theme.fonts.b3};
`;

const CountdownWrapper = styled.div`
  position: absolute;
  width: 100%;
`;

const CloseButtonWrapper = styled.div`
  display: flex;
  padding-right: 16px;
  margin-top: 8px;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;

const LoadingImage = styled.img`
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 150px;
`;

const LoadingWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${props => props.theme.purple};
`;

const LoadingText = styled.div`
  font: ${props => props.theme.fonts.h2};
  color: white;
  position: absolute;
  z-index: 100;
  top: 57%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default Preview;
