import styled from 'styled-components';

import i18n from 'i18n';
import CloseButton from 'components/CloseButton';
import sensitive from 'assets/sensitive.png';
import FingerPoint, { Tips } from './FingerPoint';
import Conditional from './Conditional';

const Mask = ({ isSensitive, isShowingTips, onClickCloseButton, onEnter, onLeave, onTrigger }) => {
  return (
    <Wrapper>
      <CloseButtonWrapper style={{ marginTop: 32 }}>
        <CloseButton onClick={() => onClickCloseButton()} />
      </CloseButtonWrapper>

      <StyledFingerPoint
        $isSensitive={isSensitive}
        onEnter={onEnter}
        onLeave={onLeave}
        isShowingTips={isShowingTips}
        onTrigger={onTrigger}
      />
      <Conditional condition={isSensitive && isShowingTips}>
        <ContentWrapper>
          <TopWrapper>
            <SensitiveImg src={sensitive} />
            <Title>{i18n.t('sensitive_warning_title')}</Title>
            <Desc>{i18n.t('sensitive_warning_desc')}</Desc>
          </TopWrapper>
        </ContentWrapper>
      </Conditional>
    </Wrapper>
  );
};

export default Mask;

const Wrapper = styled.div`
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                        supported by Chrome, Edge, Opera and Firefox */
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  z-index: 99;
  display: flex;
  margin: 20px;
  flex-direction: column;
  justify-content: space-between;
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 16px;
  gap: 16px;
  margin-top: 8px;
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
`;

const SensitiveImg = styled.img`
  width: 76px;
  margin-bottom: 16px;
`;

const Title = styled.div`
  text-align: center;
  margin-bottom: 10px;
  font: ${props => props.theme.fonts.h2};
  color: ${props => props.theme.white};
`;

const Desc = styled.div`
  text-align: center;
  color: ${props => props.theme.white};
  font: ${props => props.theme.fonts.b3};
`;

const StyledFingerPoint = styled(FingerPoint)`
  > ${Tips} {
    margin-top: ${props => (props.$isSensitive ? '10%' : '40%')};
  }
`;

// const ShowImageButton = styled.div`
//   color: ${(props) => props.theme.dark};
//   background-color: ${(props) => props.theme.white};
//   font: ${(props) => props.theme.fonts.h3};
//   padding: 20px;
//   margin-bottom: 20px;
//   text-align: center;
//   border-radius: 24px;
//   cursor: pointer;
//   margin-top: auto;
// `;
