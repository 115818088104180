import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

const LINE_TITLE = 'Line 如何傳送閱後即焚的照片 [2023最新]';
const LINE_DESC =
  '有時候跟男女朋友或是曖昧對象想傳一些羞羞的照片🙈🙈，但又怕被存下來，用 Line 收回照片功能又很麻煩，你可以試試看 YOSO App 在 Line 聊天時，傳送閱後即焚的照片，照片只能看五秒鐘就自動銷毀。';
const TINDER_TITLE = 'How to Send Pictures on Tinder: Simple and Safe [2023]';
const TINDER_DESC =
  'With YOSO, you can send photos directly in your Tinder chat that will disappear after just 5 seconds.';

const Blog = ({ type }) => {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    const fetchHtmlFile = async () => {
      const response = await fetch(`./static/blog/${type}.txt`);
      const html = await response.text();

      let regex = new RegExp('https:\\/\\/yoso\\.page\\.link\\/landing', 'g');
      const newHtml = html.replace(regex, `https://yoso.page.link/blog-${type}`);

      setHtmlContent(newHtml);
    };

    fetchHtmlFile();
  }, []);

  return (
    <>
      {type === 'line' ? (
        <Helmet>
          <title>{LINE_TITLE}</title>
          <meta content={LINE_DESC} name="description" />
          <meta content={LINE_TITLE} property="og:title" />
          <meta content={LINE_DESC} property="og:description" />
          <meta content={LINE_TITLE} property="twitter:title" />
          <meta content={LINE_DESC} property="twitter:description" />
        </Helmet>
      ) : (
        <Helmet>
          <title>{TINDER_TITLE}</title>
          <meta content={TINDER_DESC} name="description" />
          <meta content={TINDER_TITLE} property="og:title" />
          <meta content={TINDER_DESC} property="og:description" />
          <meta content={TINDER_TITLE} property="twitter:title" />
          <meta content={TINDER_DESC} property="twitter:description" />
        </Helmet>
      )}

      <div
        style={{ background: '#ffffff' }}
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      ></div>
    </>
  );
};

export default Blog;
