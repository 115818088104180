import styled from 'styled-components';

import i18n from 'i18n';
import Brand from 'components/Brand';
import Header from 'components/Header';
import logo from 'assets/logo.png';
import appStore from 'assets/ios.png';
import googlePlay from 'assets/android.png';
import { GOOGLE_PLAY_LINK, APP_STORE_LINK } from 'constant';
import { logEvent } from 'core/bi/biUtils';

const ExpiredContent = () => {
  return (
    <ExpiredWrapper>
      <Wrapper>
        <Header />
        <ContentWrapper>
          <ExpiredText>{i18n.t('expired_text')}</ExpiredText>
          <Logo src={logo} />
          <TextWrapper>
            <Brand />
            <Desc>{i18n.t('brand_desc')}</Desc>
          </TextWrapper>

          <LinkWrapper>
            <a
              onClick={() => {
                logEvent('web_CTA_click_app_store');
                window.location.href = APP_STORE_LINK;
              }}
              // href={GOOGLE_PLAY_LINK}
              rel="noopener noreferrer"
            >
              <LinkImage alt="yoso-app-store" src={appStore} />
            </a>
            <a
              onClick={() => {
                logEvent('web_CTA_click_play_store');
                window.location.href = GOOGLE_PLAY_LINK;
              }}
              // href={APP_STORE_LINK}
              rel="noopener noreferrer"
            >
              <LinkImage alt="yoso-google-play" src={googlePlay} />
            </a>
          </LinkWrapper>
        </ContentWrapper>
      </Wrapper>
    </ExpiredWrapper>
  );
};

export default ExpiredContent;

const ExpiredWrapper = styled.div`
  background: linear-gradient(to bottom, #c6aef7, #f4dfe2, #ffffff);
  width: 100%;
  height: 100%;
`;

const Wrapper = styled.div`
  max-width: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: auto;
`;

const Logo = styled.img`
  width: 160px;
  height: 160px;
  margin: 0 auto 20px auto;
`;

const LinkWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  min-height: 150px;
`;

const LinkImage = styled.img`
  width: 180px;
  cursor: pointer;
  focus: pointer;
`;

const ContentWrapper = styled.div`
  z-index: 99;
  height: 80%;
  display: flex;
  margin: 20px;
  flex-direction: column;
  justify-content: space-between;
`;

const ExpiredText = styled.div`
  font: ${(props) => props.theme.fonts.b2};
  color: ${(props) => props.theme.purple};
  background-color: rgba(230, 232, 236, 0.4);
  padding: 12px 24px;
  border-radius: 24px;
  text-align: center;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Desc = styled.div`
  text-align: center;
  color: ${(props) => props.theme.grey};
  font: ${(props) => props.theme.fonts.b4};
  margin-top: 8px;
`;
