import styled from 'styled-components';
import i18n from 'i18n';
import brandImg from 'assets/brand.png';

const Brand = ({ isLogo, styled }) => {
  return (
    <>
      {isLogo ? (
        <BrandImg styled={styled} src={brandImg} />
      ) : (
        <BrandText styled={styled}>{i18n.t('brand_title')}</BrandText>
      )}
    </>
  );
};

const BrandImg = styled.img`
  height: 30px;
`;

const BrandText = styled.div`
  color: ${(props) => props.theme.dark};
  font: ${(props) => props.theme.fonts.h2};
  text-decoration: none;
`;

export default Brand;
