import closeIcon from 'assets/web_close.png';

const CloseButton = ({ onClick }) => {
  return (
    <img
      onClick={() => onClick()}
      src={closeIcon}
      style={{ width: 24, height: 24 }}
    />
  );
};

export default CloseButton;
