const colors = {
  purple: '#7b51ef',
  grey: '#777e90',
  dark: '#23262F',
  white: '#ffffff',
};

const fonts = {
  h1: `800 28px/32px Arial`,
  h2: `600 20px/32px Arial`,
  h3: `600 17px/22px Arial`,
  b1: `400 17px/22px Arial`,
  b2: `600 15px/22px Arial`,
  b3: `400 15px/24px Arial`,
  b4: `600 13px/24px Arial`,
};

const theme = {
  default: {
    ...colors,
    fonts,
    backgroundColor: colors.dark,
  },
};

export default theme;
