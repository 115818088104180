import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { language as browserLanguage } from 'i18n';

const Layout = () => {
  let locale = browserLanguage.replace('-', '_');
  if (locale === 'en') locale = 'en_US';
  else if (locale === 'zh') locale = 'zh_TW';

  return (
    <>
      <Helmet>
        <meta content={locale} property="og:locale" />
      </Helmet>
      <Container>
        <Wrapper>
          <Outlet />
        </Wrapper>
      </Container>
    </>
  );
};

const Container = styled.div`
  height: 100vh;
  max-height: -webkit-fill-available;
  display: flex;
  background-color: black;
  margin: auto;
`;

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto 0 auto;
`;

export default Layout;
