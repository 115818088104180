import mixpanel from "mixpanel-browser";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent as logGAEvent } from "firebase/analytics";

const isDev = process.env.NODE_ENV === "development";

const token = isDev
  ? "0b8ca788074f0e19c022cfb1e3be3982"
  : "a2262550034052e0f99b07fc08ade40c";

const firebaseConfig = isDev
  ? {
      apiKey: "AIzaSyB-gLlZHsrbkTmNqiytJm5oquuHT8rVlSU",
      authDomain: "yoso-dev.firebaseapp.com",
      projectId: "yoso-dev",
      storageBucket: "yoso-dev.appspot.com",
      messagingSenderId: "86199626287",
      appId: "1:86199626287:web:ab17d27e2da6900a0bb4da",
      measurementId: "G-QPLTSLMF0Y",
    }
  : {
      apiKey: "AIzaSyBrDDCs4XnpTuktvg3VB5zWPcYuHR6MRJY",
      authDomain: "yoso-prod.firebaseapp.com",
      projectId: "yoso-prod",
      storageBucket: "yoso-prod.appspot.com",
      messagingSenderId: "142578708440",
      appId: "1:142578708440:web:57d79f81de95827ac30cad",
      measurementId: "G-55WM2VGRS3",
    };

// initMixPanel
mixpanel.init(token, { debug: true });
mixpanel.identify();
mixpanel.people.set({ role: "viewer" });
mixpanel.set_config({ secure_cookie: true });

// initGA
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
logGAEvent(analytics, "initGA");

export const logEvent = (...args) => {
  if (isDev) {
    console.log("analytics log event", ...args);
  }
  mixpanel.track(...args);
  logGAEvent(analytics, ...args);
};

// mixpanel functions
export const startTimeEvent = (event) => {
  mixpanel.time_event(event);
};
